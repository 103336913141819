import React from 'react'
import classNames from 'classnames'
import { AsH2, HeadingLG, ParagraphLG } from '@/atoms/Text'
import { BasicCarousel } from '@/molecules/BasicCarousel'
import { isGuildSignupSimpleLayout } from '@/services/GuildSignupPageService/validations'
import { GuildEmailInput } from '@/views/GuildJoinView/GuildEmailInput'
import { useGuildSignupContext } from '../GuildSignupContext'
import { ContentCard } from './ContentCard'

interface GuildEarlyAccessSectionProps {
  hasUser: boolean
}

export const GuildEarlyAccessSection: React.FC<GuildEarlyAccessSectionProps> = ({ hasUser }) => {
  const { guildEarlyAccessSection, layout } = useGuildSignupContext()

  if (!guildEarlyAccessSection) return null

  const { heading, subHeading, episodeListCollection } = guildEarlyAccessSection

  const isSimple = isGuildSignupSimpleLayout(layout)

  return (
    <section
      className={classNames(
        'flex scroll-mt-36 flex-col gap-24 py-36 text-white w-full overflow-x-hidden',
        isSimple ? 'bg-cool-02' : 'bg-cool-linear lg:bg-cool-radial',
      )}
      id="guild-collection"
      aria-labelledby="early-access-title"
    >
      {(heading || subHeading) && (
        <div className="mx-auto flex max-w-4xl flex-col gap-4 px-4 text-center md:px-8 lg:px-12">
          {heading && (
            <HeadingLG as={AsH2} weight="bold" id="early-access-title">
              {heading}
            </HeadingLG>
          )}
          {subHeading && <ParagraphLG color={isSimple ? 'core-gray-200' : 'core-gray-400'}>{subHeading}</ParagraphLG>}
        </div>
      )}
      <div className="mx-auto w-full max-w-full overflow-x-hidden">
        <BasicCarousel containerClassName="px-4 md:px-8 lg:px-12 4xl:justify-center">
          {episodeListCollection?.items?.map(
            (episode) => episode && <ContentCard key={episode.sys.id} content={episode} />,
          )}
        </BasicCarousel>
      </div>

      <div className="mx-auto mt-8 flex min-h-[72px] w-full max-w-[600px] items-center justify-center">
        <GuildEmailInput hasUser={hasUser} isHero={true} />
      </div>
    </section>
  )
}
