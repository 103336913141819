import React from 'react'
import { Case, Default, Switch, When } from 'react-if'
import { Image } from '@/atoms/Image'
import { ParagraphLG, ParagraphXL, TitleLG } from '@/atoms/Text'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import {
  isGuildExplainerLayout,
  isGuildSignupMultiShowLayout,
  isGuildSignupSimpleLayout,
  isGuildSignupVideoLayout,
} from '@/services/GuildSignupPageService/validations'
import { GuildHowDoesItWorkV2 } from '@/views/GuildJoinView/GuildHowDoesItWorkV2'
import { GuildNowStreamingRail } from '@/views/GuildSignupView/GuildBodySection/GuildNowStreamingRail'
import { GuildUpcomingRail } from '@/views/GuildSignupView/GuildBodySection/GuildUpcomingRail'
import { GuildEarlyAccessSection } from '@/views/GuildSignupView/GuildEarlyAccessSection'
import { GuildSimpleHero } from '@/views/GuildSignupView/GuildSimpleHero'
import { GuildVideoHero } from '@/views/GuildSignupView/GuildVideoHero'
import { GuildApprovals } from '../GuildJoinView/GuildApprovals'
import { GuildHowDoesItWork } from '../GuildJoinView/GuildHowDoesItWork'
import { GuildPromotionBanner } from '../GuildJoinView/GuildPromotionBanner'
import { GuildBodySection } from './GuildBodySection'
import { GuildExplainerLayout } from './GuildExplainerLayout'
import { GuildMultiShowHero } from './GuildMultiShowHero'
import { useGuildSignupContext } from './GuildSignupContext'
import { GuildSingleShowLayout } from './GuildSingleShowLayout'
import { GuildTestimonialSection } from './GuildTestimonialSection'

interface GuildSignupViewProps {
  hasUser: boolean
}

export const GuildSignupView: React.FC<GuildSignupViewProps> = ({ hasUser }) => {
  const { slug, layout, guildExplainerLayout, guildEarlyAccessSection, guildTestimonialSection, experiments } =
    useGuildSignupContext()

  const multiShowLayout = isGuildSignupMultiShowLayout(layout)
  const canShowVideoHeroLayout = isGuildSignupVideoLayout(layout)
  const canShowExplainerLayout = isGuildExplainerLayout(guildExplainerLayout)
  const isSimpleLayout = isGuildSignupSimpleLayout(layout)

  return (
    <main className="h-full w-full se:overflow-x-hidden">
      <ThemeContextProvider isDarkMode={!isSimpleLayout}>
        <GuildPromotionBanner />
        <Switch>
          <Case condition={canShowVideoHeroLayout}>
            <GuildVideoHero hasUser={hasUser} />
          </Case>
          <Case condition={multiShowLayout}>
            <GuildMultiShowHero />
          </Case>
          <Case condition={isSimpleLayout}>
            <GuildSimpleHero hasUser={hasUser} />
          </Case>
          <Default>
            <GuildSingleShowLayout />
          </Default>
        </Switch>
        <When condition={slug === 'bitcoin-and-the-beast'}>
          <section className="relative bg-[#ffc409] py-12">
            <div className="relative">
              <div className="flex items-center bg-transparent">
                <div className="flex w-full flex-col items-center justify-end gap-4 p-4 sm:p-10 lg:flex-row se:p-2">
                  <div className="flex flex-1 justify-center">
                    <div className="w-fit rounded-full bg-white p-4">
                      <Image
                        className="rounded-full"
                        priority
                        width={500}
                        height={500}
                        alt=""
                        src="/v1725139499/angel-studios/guild/robert-breedlove.webp"
                      />
                    </div>
                  </div>
                  <div className="flex w-full flex-1 items-center justify-center">
                    <div className="flex max-w-lg flex-col justify-center gap-y-6">
                      <div className="flex flex-col gap-y-8">
                        <div className="rounded-2xl bg-white px-6 py-10">
                          <ParagraphXL className="mb-6">
                            &ldquo;The best way to improve the world is to educate children about the tremendous value
                            of individual freedom, rationality, and the pursuit of truth. To this end,{' '}
                            <span className="font-bold">
                              Tuttle Twins is an enlightening and entertaining tool for teaching children.
                            </span>
                            &rdquo;
                          </ParagraphXL>
                          <ParagraphXL>&mdash; Robert Breedlove</ParagraphXL>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </When>
        <When condition={canShowExplainerLayout}>
          {guildExplainerLayout && (
            <GuildExplainerLayout guildExplainerLayout={guildExplainerLayout} hasUser={hasUser} />
          )}
        </When>
        <When condition={!isSimpleLayout}>
          <div className="overflow-hidden">
            <GuildApprovals />
          </div>
        </When>
        <When condition={slug !== 'bitcoin-and-the-beast'}>
          <GBFadeInExperiment defaultValue={false} experimentName="project-focused-how-it-works-section">
            <ExperimentVariation variation={true}>
              <ThemeContextProvider isDarkMode={!isSimpleLayout}>
                <GuildHowDoesItWorkV2 />
              </ThemeContextProvider>
            </ExperimentVariation>
            <ExperimentVariation variation={false}>
              <GuildHowDoesItWork hasUser={hasUser} />
            </ExperimentVariation>
          </GBFadeInExperiment>
        </When>

        <When condition={slug === 'bitcoin-and-the-beast'}>
          <section className="relative bg-[#6E4799] py-12">
            <div className="relative">
              <div className="flex items-center bg-transparent">
                <div className="flex w-full flex-col items-center justify-end gap-4 p-4 sm:p-10 lg:flex-row lg:items-center lg:justify-center se:p-2">
                  <div className="flex w-full flex-1 items-center justify-center lg:order-1">
                    <div className="flex max-w-lg flex-col justify-center gap-y-6">
                      <div className="flex flex-col gap-y-8">
                        <div className="flex flex-col gap-6 rounded-2xl bg-white px-6 py-10">
                          <TitleLG weight="bold" className="mb-6">
                            Parent Vetted and Parent Approved
                          </TitleLG>
                          <ParagraphLG>A lot of the content made today is mindless or worse.</ParagraphLG>
                          <ParagraphLG>
                            Each episode of Tuttle Twins is parent-funded, parent-vetted and only released when it’s
                            parent-approved.
                          </ParagraphLG>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:order-0 flex max-w-3xl flex-1 items-center justify-center">
                    <Image
                      priority
                      width={345}
                      height={800}
                      alt=""
                      src="/v1715183356/angel-studios/projects/tuttle-twins/the-tuttletwins-phone-facing-right-1.webp"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </When>

        <When condition={Boolean(guildTestimonialSection)}>
          <GuildTestimonialSection hasUser={hasUser} />
        </When>

        {Boolean(experiments['guild-signup-content-rails']) && (
          <>
            <section className="mb-12 overflow-x-hidden px-4 scrollbar-none sm:px-8 md:px-12 xl:px-16">
              <GuildNowStreamingRail />
            </section>
            <section className="mb-12 overflow-x-hidden px-4 scrollbar-none sm:px-8 md:px-12 xl:px-16">
              <GuildUpcomingRail />
            </section>
          </>
        )}

        <When condition={Boolean(guildEarlyAccessSection && !experiments['guild-signup-content-rails'])}>
          <GuildEarlyAccessSection hasUser={hasUser} />
        </When>
        <GuildBodySection hasUser={hasUser} />
      </ThemeContextProvider>
    </main>
  )
}
