import React from 'react'
import { CaptionSM } from '@/atoms/Text'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { useTimeLeft } from '@/utils/useTimeLeft/useTimeLeft'

export interface GuildPromotionCountdownSimplifiedProps {
  endDate: string
}
export const GuildPromotionCountdownSimplified: React.FC<GuildPromotionCountdownSimplifiedProps> = ({ endDate }) => {
  const { t } = useTranslate('common')
  const today = new Date()

  const timeLeft = useTimeLeft({ targetDate: new Date(endDate) })

  if (today > new Date(endDate)) return null

  const daysLeft = timeLeft.days
  const hoursLeft = timeLeft.hours
  const showHours = daysLeft < 1

  const roundedDaysLeft = daysLeft + 1

  return (
    <div className="">
      <CaptionSM className="!text-[#F45A3B]" color="inherit" weight="bold">
        {showHours ? (
          <Translate i18nKey="hoursLeftToSave" t={t} values={{ hoursLeft }}>
            {{ hoursLeft }} Hours left to save
          </Translate>
        ) : (
          <Translate i18nKey="daysLeftToSave" t={t} values={{ roundedDaysLeft }}>
            {{ roundedDaysLeft }} Days left to save
          </Translate>
        )}
      </CaptionSM>
    </div>
  )
}
