import React, { useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { When } from 'react-if'
import { Image } from '@/atoms/Image'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsFigCaption, AsH3, CaptionMD, CaptionSM, EyebrowLG, HeadingXS, TitleMD } from '@/atoms/Text'
import { formatDimensions, formatTitleLogo } from '@/services/ContentCatalog'
import { ContentfulCloudinaryImageType } from '@/services/EmailCapturePageService/EmailCapturePageService'
import { buildCloudinaryImagePathFromContentfulObject, getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useTranslate } from '@/utils/translate/translate-client'
import { GuildEmailInput } from '@/views/GuildJoinView/GuildEmailInput'
import { useGuildSignupContext } from '../GuildSignupContext'

interface GuildTestimonialSectionProps {
  hasUser: boolean
}

export const GuildTestimonialSection: React.FC<GuildTestimonialSectionProps> = ({ hasUser }) => {
  const { t } = useTranslate('guild')
  const { guildTestimonialSection, catalogTitle } = useGuildSignupContext()
  const [currentSlide, setCurrentSlide] = useState(0)
  const titleLogo = formatTitleLogo(catalogTitle)
  const {
    desktopBackgroundImageUrl,
    tabletBackgroundImageUrl,
    mobileBackgroundImageUrl,
    testimonialsCollection,
    audience,
    cinemaScore,
    tomatometer,
  } = guildTestimonialSection ?? {}

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentSlide === (testimonialsCollection?.items?.length ?? 0) - 1) {
        setCurrentSlide(0)
      } else {
        setCurrentSlide(currentSlide + 1)
      }
    }, 6500)
    return () => clearInterval(interval)
  }, [currentSlide, testimonialsCollection?.items?.length])

  if (!guildTestimonialSection) return null

  return (
    <section
      aria-labelledby="guild-testimonial-title"
      id="guild-testimonial"
      className="relative flex w-full flex-col items-center text-pretty px-4 py-16 text-center text-white md:items-start md:px-2 md:py-32 lg:px-20"
    >
      {desktopBackgroundImageUrl && (
        <ResponsiveBackgroundImage
          src={desktopBackgroundImageUrl}
          className="md:bg-top-right bg-cover bg-bottom bg-no-repeat"
          preBackgroundStyles="linear-gradient(180deg, rgba(21, 21, 21, 0.3) 60%, #151515 100%), "
          srcUrls={{
            xs: mobileBackgroundImageUrl ?? desktopBackgroundImageUrl,
            sm: mobileBackgroundImageUrl ?? desktopBackgroundImageUrl,
            md: tabletBackgroundImageUrl ?? desktopBackgroundImageUrl,
            lg: desktopBackgroundImageUrl,
            xl: desktopBackgroundImageUrl,
            '2xl': desktopBackgroundImageUrl,
          }}
        />
      )}
      <div className="relative flex w-full flex-col items-center gap-8 text-center text-white md:w-auto">
        <div className="flex max-w-[107px] flex-col items-center gap-6 md:max-w-[130px] md:p-0">
          {titleLogo && (
            <Image
              alt={'Guild Logo'}
              className="min-w-[178px]"
              src={getCloudinaryImageUrl({
                path: titleLogo.cloudinaryPath,
                transforms: 'e_trim',
                ...formatDimensions([500, 'auto'], titleLogo),
              })}
              {...formatDimensions([500, 'auto'], titleLogo)}
            />
          )}
          <EyebrowLG weight="semibold" className="uppercase" id="guild-testimonial-title">
            {t('reviews', 'Reviews')}
          </EyebrowLG>
        </div>
        <Transition
          show
          appear
          enter="transition-opacity duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-5500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Testimonial
            quote={testimonialsCollection?.items?.[currentSlide]?.quote ?? ''}
            author={testimonialsCollection?.items?.[currentSlide]?.author ?? ''}
          />
        </Transition>
        <ul className="flex h-full min-w-[98px] flex-row gap-1 md:items-end">
          {testimonialsCollection?.items?.map((item, index) => {
            const itemKey = `${index}${item}`
            const key = `${item}${index}`

            return (
              <li
                key={itemKey}
                onClick={() => setCurrentSlide(index)}
                className="flex cursor-pointer items-center justify-center p-1"
              >
                <Transition
                  show={currentSlide === index}
                  className="max-h-[10px] overflow-hidden rounded-full"
                  enter="transition ease-in-out duration-850"
                  enterFrom="-translate-x-4 scale-x-0 w-2.5 bg-gray-500"
                  enterTo="translate-x-0 scale-x-1 w-8 bg-white"
                >
                  <div
                    className={classNames('bg-white max-h-2.5 h-2.5 w-2.5 rounded-full ', {
                      '!w-8': currentSlide === index,
                    })}
                    key={key}
                  />
                </Transition>
                {currentSlide !== index && (
                  <div
                    className={classNames('h-2.5 w-2.5 rounded-full bg-gray-500', {
                      'bg-white !w-8': currentSlide === index,
                    })}
                    key={key}
                  />
                )}
              </li>
            )
          })}
        </ul>
        <div className="flex flex-row gap-6 px-5 lg:gap-12">
          <When condition={Boolean(tomatometer)}>
            <Rating icon={tomatometer?.icon} rating={tomatometer?.rating} author={tomatometer?.author} />
          </When>
          <When condition={Boolean(audience)}>
            <Rating icon={audience?.icon} rating={audience?.rating} author={audience?.author} />
          </When>
          <When condition={Boolean(cinemaScore)}>
            <Rating icon={cinemaScore?.icon} rating={cinemaScore?.rating} author={cinemaScore?.author} />
          </When>
        </div>
        <div className="mb-0 flex min-h-[72px] w-full items-center justify-center">
          <GuildEmailInput hasUser={hasUser} isHero={true} />
        </div>
      </div>
    </section>
  )
}

const Testimonial = ({ quote, author }: { quote?: string; author?: string }) => {
  return (
    <div className="flex min-h-[211px] flex-col justify-between gap-[17px] rounded-2xl bg-core-gray-700/30 px-6 py-10 backdrop-blur-2xl md:min-w-[326px] md:max-w-[326px] lg:min-w-[452px] lg:max-w-[452px] xl:min-w-[537px] xl:max-w-[537px] 2xl:min-w-[576px] 2xl:max-w-[576px]">
      <TitleMD className="2xl:photon-title-lg text-pretty">{quote}</TitleMD>
      <CaptionMD>{author}</CaptionMD>
    </div>
  )
}

const Rating = ({
  icon,
  rating,
  author,
  size = 56,
}: {
  icon: ContentfulCloudinaryImageType | ContentfulCloudinaryImageType[]
  rating: string | undefined | null
  author: string | undefined | null
  size?: number
}) => {
  return (
    <figure className={classNames('flex flex-col items-center m-4 text-core-gray-400')}>
      <div className="mx-auto w-[56px]">
        <Image
          src={buildCloudinaryImagePathFromContentfulObject(icon)}
          width={size}
          height={size}
          alt={`${author} Rating`}
        />
      </div>
      <div className="text-center">
        <HeadingXS as={AsFigCaption} className="mb-1 mt-2">
          {rating}
        </HeadingXS>
        <CaptionSM as={AsH3}>{author}</CaptionSM>
      </div>
    </figure>
  )
}
