import React from 'react'
import classNames from 'classnames'
import { When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { TitleMD } from '@/atoms/Text'
import { FaqAccordion } from '@/organisms/FaqAccordion'
import { isGuildSignupSimpleLayout } from '@/services/GuildSignupPageService/validations'
import { Faq } from '@/types/codegen-contentful'
import { useTranslate } from '@/utils/translate/translate-client'
import { GuildEmailInput } from '@/views/GuildJoinView/GuildEmailInput'
import { GuildUpcomingColumns } from '@/views/GuildSignupView/GuildBodySection/GuildUpcomingColumns/GuildUpcomingColumns'
import { AppPromoSection } from '@/views/LandingView/AppPromoSection'
import { useGuildSignupContext } from '../GuildSignupContext'

interface GuildBodySectionProps {
  className?: string
  hasUser: boolean
}

export const GuildBodySection: React.FC<GuildBodySectionProps> = ({ className, hasUser }) => {
  const { guildFaqs, layout, experiments } = useGuildSignupContext()
  const { t } = useTranslate('join-the-guild')
  const isSimple = isGuildSignupSimpleLayout(layout)

  return (
    <div
      className={classNames(
        'flex flex-col items-center text-center overflow-x-hidden',
        className,
        isSimple ? 'bg-core-gray-200 text-black' : 'bg-core-gray-950 text-white',
      )}
    >
      {Boolean(!isSimple && !experiments['guild-signup-content-rails']) && <GuildUpcomingColumns />}

      <AppPromoSection containerClassName="h-full pt-10 !pb-0" simplified />

      <LazyHydrate whenVisible>
        <>
          <section
            aria-label={t('joinTheMovement', 'Join the movement.')}
            id="plan-selection"
            className="flex w-full flex-col items-center bg-core-gray-100 py-[104px] text-center text-core-gray-950"
          >
            <TitleMD
              weight="bold"
              className="md:photon-heading-sm lg:photon-heading-xl max-w-[700px] pb-10 md:pb-6 lg:pb-0"
            >
              {t(
                'joinMovementWatchSupportEntertainment',
                'Join the Movement to watch and support entertainment that Amplifies Light',
              )}
            </TitleMD>
            <LazyHydrate whenVisible>
              <GuildEmailInput hasUser={hasUser} isHero={false} />
            </LazyHydrate>
          </section>
        </>
      </LazyHydrate>
      <When condition={(guildFaqs?.length || 0) > 0}>
        <section
          aria-label={t('frequentlyAskedQuestions', 'Frequently Asked Questions')}
          id="faqs"
          key="faqs"
          className={classNames(
            'mx-auto scroll-mt-[73px] px-8 py-16 text-left md:max-w-[654px]',
            isSimple ? 'text-black' : 'text-white',
          )}
        >
          <FaqAccordion faqs={guildFaqs as Faq[]} showTopBorder={true} />
        </section>

        <div className="mb-8 flex min-h-[72px] w-full max-w-[600px] items-center justify-center">
          <GuildEmailInput hasUser={hasUser} isHero={true} />
        </div>
      </When>
    </div>
  )
}
