import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const CheckBoxIcon: React.FC<OneColorIconProps> = ({ className, color = 'white', size = 16 }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.83333 35.7399C7.91667 35.7399 7.13167 35.4138 6.47833 34.7615C5.82611 34.1082 5.5 33.3232 5.5 32.4065V9.0732C5.5 8.15653 5.82611 7.37153 6.47833 6.7182C7.13167 6.06598 7.91667 5.73987 8.83333 5.73987H32.1667C33.0833 5.73987 33.8683 6.06598 34.5217 6.7182C35.1739 7.37153 35.5 8.15653 35.5 9.0732V32.4065C35.5 33.3232 35.1739 34.1082 34.5217 34.7615C33.8683 35.4138 33.0833 35.7399 32.1667 35.7399H8.83333ZM8.83333 32.4065H32.1667V9.0732H8.83333V32.4065ZM18.1667 27.0315C18.3889 27.0315 18.5972 26.9971 18.7917 26.9282C18.9861 26.8582 19.1667 26.7399 19.3333 26.5732L28.7917 17.1149C29.0972 16.8093 29.25 16.4343 29.25 15.9899C29.25 15.5454 29.0833 15.1565 28.75 14.8232C28.4444 14.5176 28.0556 14.3649 27.5833 14.3649C27.1111 14.3649 26.7222 14.5176 26.4167 14.8232L18.1667 23.0732L14.5417 19.4482C14.2361 19.1426 13.8611 18.9899 13.4167 18.9899C12.9722 18.9899 12.5833 19.1565 12.25 19.4899C11.9444 19.7954 11.7917 20.1843 11.7917 20.6565C11.7917 21.1288 11.9444 21.5176 12.25 21.8232L17 26.5732C17.1667 26.7399 17.3472 26.8582 17.5417 26.9282C17.7361 26.9971 17.9444 27.0315 18.1667 27.0315Z"
        fill={getHexColor(color)}
      />
    </svg>
  )
}
