import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import classNames from 'classnames'
import { When } from 'react-if'
import { LinkButton } from '@/atoms/Button'
import { CheckBoxIcon } from '@/atoms/Icons/CheckBoxIcon'
import { EarlyAccessIcon } from '@/atoms/Icons/EarlyAccessIcon'
import { GiftIcon } from '@/atoms/Icons/GiftIcon'
import { GuildHighResShieldIcon } from '@/atoms/Icons/GuildHighResShieldIcon'
import { LightIcon } from '@/atoms/Icons/LightIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { AsH1, AsSpan, CaptionMD, HeadingXS, LabelSM, ParagraphSM } from '@/atoms/Text'
import { options } from '@/services/ContentfulService'
import { isGuildSignupVideoLayout } from '@/services/GuildSignupPageService/validations'
import { useTranslate } from '@/utils/translate/translate-client'
import { GuildEmailInput } from '@/views/GuildJoinView/GuildEmailInput'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'

interface GuildVideoHeroProps {
  hasUser: boolean
}
export const GuildVideoHero: React.FC<GuildVideoHeroProps> = ({ hasUser }) => {
  const { layout, experiments } = useGuildSignupContext()
  const { t } = useTranslate('guild')

  if (!isGuildSignupVideoLayout(layout)) {
    return null
  }

  const videoLayout = layout
  const showHeroCta = Boolean(videoLayout.heroCta)
  const hideIcons = experiments['guild-signup-hide-icons-above-fold']

  return (
    <section
      aria-label={videoLayout.heading}
      className="2xl:max-h-auto relative h-full min-h-screen w-full overflow-x-hidden"
    >
      <div className="aspect-h-9 aspect-w-16 h-screen min-h-screen min-w-full bg-cover md:min-h-[800px] se:min-h-[750px]">
        <video
          className="h-full min-h-full bg-cover object-cover md:max-h-[800px]"
          autoPlay
          playsInline
          muted
          loop
          src={layout.backgroundVideoUrl}
        />
        <div
          className="absolute inset-0 h-full min-h-full w-full md:max-h-[800px]"
          style={{ background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.10) 100%)' }}
        />
      </div>
      <div className="absolute left-0 top-0 z-10 h-screen min-h-screen w-full bg-transparent px-4 py-16 md:max-h-[800px] md:px-20 md:py-32 xl:mx-[180px] xl:px-4">
        <div
          className={classNames('relative flex h-full w-full flex-col items-center justify-center', {
            '!justify-start !mt-8 md:!mt-0 md:!justify-center': showHeroCta,
          })}
        >
          <div className="flex w-full flex-col items-center justify-center gap-y-4 bg-transparent md:items-start md:gap-y-6">
            <div>
              <When condition={!showHeroCta}>
                <div className="flex flex-col items-center gap-y-1">
                  <GuildHighResShieldIcon className="invisible h-0 w-0 md:visible md:h-full md:w-fit" size={64} />
                  <GuildHighResShieldIcon
                    className="invisible h-0 w-0 sm:visible sm:h-full sm:w-fit md:invisible md:h-0 md:w-0"
                    size={48}
                  />
                  <GuildHighResShieldIcon className="visible h-auto w-auto sm:invisible sm:h-0 sm:w-0" size={40} />
                  <ParagraphSM color="white" className="uppercase">
                    {t('guild', 'Guild')}
                  </ParagraphSM>
                </div>
              </When>
            </div>
            <div className="flex max-w-[600px] flex-col gap-y-4 se:max-w-full">
              <HeadingXS
                as={AsH1}
                weight="bold"
                className="se:photon-title-lg md:photon-heading-lg 2xl:photon-heading-xl w-full text-pretty bg-transparent text-center md:text-left"
                color="white"
              >
                {videoLayout.heading}
              </HeadingXS>
              <ParagraphSM
                as={AsSpan}
                className="[&>p]:photon-paragraph-sm md:[&>p]:photon-paragraph-md se:[&>p]:photon-caption-xs 2xl:[&>p]:photon-paragraph-xl w-full text-pretty bg-transparent text-center md:text-left"
                color="white"
              >
                {documentToReactComponents(videoLayout.paragraph.json, options)}
              </ParagraphSM>
            </div>
            <When condition={!hideIcons}>
              <div className="mb-4 flex items-center justify-between gap-x-2 md:mb-8 md:gap-x-4">
                <div className="flex max-w-20 flex-col items-center gap-y-2">
                  <CheckBoxIcon color="white" size={24} />
                  <CaptionMD color="core-gray-400" className="se:photon-caption-sm text-center">
                    {t('voteForShows', 'Vote for shows')}
                  </CaptionMD>
                </div>
                <div className="flex max-w-20 flex-col items-center gap-y-2">
                  <EarlyAccessIcon color="white" size={24} />
                  <CaptionMD color="core-gray-400" className="se:photon-caption-sm text-center">
                    {t('earlyAccess', 'Early Access')}
                  </CaptionMD>
                </div>
                <div className="flex max-w-20 flex-col items-center gap-y-2">
                  <TicketIcon color="white" size={24} />
                  <CaptionMD color="core-gray-400" className="se:photon-caption-sm text-center">
                    {t('movieTickets', 'Movie Tickets')}
                  </CaptionMD>
                </div>
                <div className="flex max-w-20 flex-col items-center gap-y-2">
                  <GiftIcon color="white" size={24} />
                  <CaptionMD color="core-gray-400" className="se:photon-caption-sm text-center">
                    {t('merchDiscounts', 'Merch Discounts')}
                  </CaptionMD>
                </div>
                <div className="flex max-w-20 flex-col items-center gap-y-2">
                  <LightIcon color="white" size={24} />
                  <CaptionMD color="core-gray-400" className="se:photon-caption-sm text-center">
                    {t('amplifyLight', 'Amplify Light')}
                  </CaptionMD>
                </div>
              </div>
            </When>

            <div className="flex w-full max-w-[600px] flex-col">
              <GuildEmailInput hasUser={hasUser} isCentered={false} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute inset-0 h-full w-[130%]"
        style={{ background: 'linear-gradient(180deg, rgba(20, 20, 20, 0.00) 0%, #222222 100%)' }}
      />
      <When condition={showHeroCta}>
        <div
          className="absolute bottom-0 left-[-50%] w-[200%] pt-1 text-white sm:left-[-40%] sm:w-[180%] md:left-[-25%] md:w-[150%] lg:left-[-15%] lg:w-[130%]"
          style={{
            background: 'linear-gradient(to bottom right, #EDD7C6, #D6A177, #FEDEC6, #D48D59, #A66141, #612B12)',
            borderTopLeftRadius: '50% 100%',
            borderTopRightRadius: '50% 100%',
          }}
        >
          <div
            className="flex w-full max-w-full flex-col items-center justify-center gap-2 bg-[#222222] pb-4 pt-2 md:flex-row md:gap-4 md:pt-12 lg:pt-10 xl:min-h-[180px] xl:pt-6"
            style={{ borderTopLeftRadius: '50% 100%', borderTopRightRadius: '50% 100%' }}
          >
            <div>
              <GuildHighResShieldIcon className="invisible h-0 w-0 md:visible md:h-full md:w-fit" size={64} />
              <GuildHighResShieldIcon
                className="invisible h-0 w-0 sm:visible sm:h-full sm:w-fit md:invisible md:h-0 md:w-0"
                size={48}
              />
              <GuildHighResShieldIcon className="visible h-auto w-auto sm:invisible sm:h-0 sm:w-0" size={36} />
            </div>
            <div className="relative !z-[9999] flex max-w-[95vw] flex-col justify-between gap-2 rounded-2xl bg-core-gray-800 p-4 md:flex-row md:gap-10">
              {videoLayout.heroCta?.description && (
                <ParagraphSM className="lg:photon-paragraph-md max-w-[364px] lg:max-w-[416px] se:max-w-[312px] se:text-xs">
                  {videoLayout.heroCta.description}
                </ParagraphSM>
              )}
              <LinkButton
                variant="black"
                className="!mb-0 max-h-[54px] max-w-[342px] !cursor-pointer whitespace-nowrap rounded-[14px] !border-core-gray-950 !bg-core-gray-950 px-8 py-[14px]"
                href={videoLayout.heroCta?.buttonHref ?? '#plan-selection'}
              >
                <LabelSM color="white" className="lg:photon-label-lg !font-medium">
                  {videoLayout.heroCta?.buttonText ?? t('learnMore', 'Learn More')}
                </LabelSM>
              </LinkButton>
            </div>
          </div>
        </div>
      </When>
    </section>
  )
}
