import { formatContentfulObjectFromCloudinaryUrl } from '@/utils/Cloudinary'
import { useTranslate } from '@/utils/translate/translate-client'
import { Release } from './Releases'

export function useStreamingReleases(): Release[] {
  const { t } = useTranslate('guild')

  return [
    {
      name: '23 Blast',
      description: t(
        'twentyThreeBlastDescription',
        `A high school football hero contracts an infection that renders him blind overnight.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1729201656/studio-app/catalog/7f3f0ca7-77cd-492d-b1dd-77ca0ac7a557.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1729201642/studio-app/catalog/026cec0b-40e8-4b38-a478-bf480f3b097c.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'The Way',
      description: t(
        'theWayShortDescription',
        `A man whose son died while trekking the “Camino de Santiago” embarks on the pilgrimage himself.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1727906391/studio-app/catalog/c191b995-bd55-43ed-9c91-7acf6aa4223d.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1727906312/studio-app/catalog/7ba591a7-bcb7-4af5-8846-6b5922021247.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Sweetwater',
      description: t(
        'sweetwaterShortDescription',
        `Nat 'Sweetwater' Clifton is recruited to become the first African American in the NBA.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1726163706/studio-app/catalog/ba96866d-781d-4c41-858a-777e6768e276.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1726163039/studio-app/catalog/f66f5886-f760-4422-a9c4-bd701c1d95d2.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Surprised by Oxford',
      description: t(
        'oxfordShortDescription',
        `As Caro starts a PhD at Oxford, a new friendship opens her up to mystery, vulnerability, and love.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1725054401/studio-app/catalog/4d399fe3-852f-42b2-8a58-29c16ee9ddfa.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1725054326/studio-app/catalog/eafad9c3-08fa-4626-9672-274103a18e29.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Something to Stand For',
      description: t(
        'somethingToStandforShortDescription',
        `Join America's favorite storyteller on an epic journey through defining movements in U.S. history.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1724882790/studio-app/catalog/0de6ee3e-2e4c-49fe-8dfd-05ba69422f0b.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1724882662/studio-app/catalog/a54c937b-c830-477e-a391-0993b3084404.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Sound of Hope',
      description: t(
        'soundOfHopeShortDescription',
        'Twenty-two families from a rural Black church adopt seventy-seven kids from the foster system.',
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1714754740/studio-app/catalog/290dbcd2-7b03-4e48-829e-a368787ff31e.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1714755270/studio-app/catalog/2327f83a-fd99-4ca9-be8f-db14867e8f24.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Sight',
      description: t(
        'sightShortDescription',
        'A world-renowned eye surgeon is tasked with restoring the sight of a blind orphan.',
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1715979503/studio-app/catalog/244fd3c4-f245-44bc-85fb-38a8391d70cc.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1715980856/studio-app/catalog/9bfa8f77-d01e-4e2e-b38d-1321f613b9dd.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Sound of Freedom',
      description: t(
        'sofShortDescription',
        'Based on the true story of a man who journeys to rescue a girl from child traffickers.',
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl('v1695672340/angel-studios/Sound-Of-Freedom-Poster.png'),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1689699692/studio-app/catalog/0d870c7c-2de1-4aa8-bf83-92326f7134d5.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Cabrini',
      description: t(
        'cabriniShortDescription',
        `Francesca Cabrini risks everything when she petitions New York's mayor to house the city's orphans.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1702574745/studio-app/catalog/8b212ace-3fbd-4fb5-9788-0b49a54b7dd0.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1703177693/studio-app/catalog/e44152f0-bf12-4e24-931b-4ce432cdc98f.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'The Shift',
      description: t(
        'shiftShortDescription',
        'A dystopian sci-fi thriller, one man is faced with infinite worlds and impossible choices.',
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1697475545/angel-studios/theatrical-releases/shift-vert-poster-2x3.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1690324274/studio-app/catalog/fa200442-9dfd-4a28-9da3-15573554cdc0.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'After Death',
      description: t('afterDeathShortDescription', 'Some saw light. Others saw darkness. All of them returned.'),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1694190506/studio-app/catalog/bf3edee1-bb16-42db-a524-b875029c9b5e.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1694190404/studio-app/catalog/12370111-e638-4dce-9135-a32826f41cd0.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    // {
    //   name: 'Young DAVID',
    //   description: t(
    //     'youndDavidShortDescription',
    //     'When David’s quest to write a song lands him far from home, he must find his way back to his sheep.',
    //   ),
    //   releaseDay: null,
    //   releaseMonth: null,
    //   releaseYear: null,
    //   caption: t('watchNow', 'Watch Now'),
    //   captionSize: '12px',
    //   verticalPoster: formatContentfulObjectFromCloudinaryUrl('v1707932054/angel-app/young-david/added-logo.webp'),
    //   horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
    //     'v1698960243/studio-app/catalog/290bcb6c-f097-4e53-bfb0-544bbde23806.webp',
    //   ),
    //   perk: t('watchNow', 'Watch Now'),
    //   perkType: 'watch',
    // },
    {
      name: 'The Wingfeather Saga',
      description: t(
        'wfsShortDescription',
        'With Fangs in pursuit, the Wingfeathers flee to the Ice Prairies—where lizard Fangs can’t follow.',
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        '/v1719342356/angel-studios/guild/wingfeather/WFS_S2Poster_f1-lowres.jpg',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1668465114/angel-app/temp-discover/wingfeather-saga-landscape.jpg',
      ),
      perk: t('earlyAccess', 'Early Access'),
      perkType: 'access',
    },
    {
      name: 'Tuttle Twins Season 3',
      description: t(
        'tuttleShortDescription',
        'The Tuttle Twins go on adventures through time and learn about liberty and freedom.',
      ),
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1715035474/studio-app/catalog/8f5c6f44-12c7-4576-9d1e-e2d9946c0531.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1715035482/studio-app/catalog/0c8a8a16-9a76-4d43-9d11-53ce0a5c6626.webp',
      ),
      perk: t('earlyAccess', 'Early Access'),
      perkType: 'access',
    },
    {
      name: 'Dry Bar Comedy LIVE',
      description: t(
        'dryBarShortDescription',
        'With over 150 million monthly views, this stand up series is truly funny for everyone.',
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl('v1695672898/angel-studios/Drybar-Poster.webp'),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl('v1695672898/angel-studios/Drybar-Poster.webp'),
      perk: t('liveRecordings', 'Live Recordings'),
      perkType: 'access',
    },
    {
      name: 'The Riot and The Dance',
      description: t(
        'riotShortDescription',
        'A documentary exploring how nature was intentionally designed by a divine creator.',
      ),
      releaseDay: null,
      releaseMonth: 'Q4',
      releaseYear: '2024',
      caption: null,
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl('v1695672487/angel-studios/Riot-Dance-Poster.webp'),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1681159754/community/stories/text_background/the-riot-and-the-dance.jpg',
      ),
      perk: t('earlyAccess', 'Early Access'),
      perkType: 'access',
    },
    {
      name: 'Bonhoeffer',
      description: t(
        'bonhoefferShortDescription',
        'The true story of Dietrich Bonhoeffer, a pastor who plotted the assassination of Hitler.',
      ),
      releaseDay: null,
      releaseMonth: 'Q4',
      releaseYear: '2024',
      caption: null,
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1707347760/studio-app/catalog/cbc0534d-66c1-47c0-ae22-23ec5e17750e.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1707347715/studio-app/catalog/15d18da6-59ce-458a-bd63-c6bec697f8b0.webp',
      ),
      perk: t('earlyAccess', 'Early Access'),
      perkType: 'access',
    },
    {
      name: 'Truth and Conviction',
      description: t(
        'truthAndConvictionShortDescription',
        'Driven by the Nazi arrest of his Jewish friend, a 16-year-old boy named Helmuth Hübener is compelled to take a stand.',
      ),
      releaseDay: null,
      releaseMonth: 'Q1',
      releaseYear: '2025',
      caption: null,
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1652710138/angel-app/for-your-consideration/Truth_and_Conviction_9x16.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1652710115/angel-app/for-your-consideration/Truth_and_Conviction_16x9.webp',
      ),
      perk: t('earlyAccess', 'Early Access'),
      perkType: 'access',
    },
  ]
}
