import React from 'react'
import classNames from 'classnames'
import { CheckIcon } from '@/atoms/Icons/CheckIcon'
import { Image } from '@/atoms/Image'
import { AsH2, AsH3, AsSpan, EyebrowMD, HeadingXS, ParagraphMD, ParagraphSM, TitleSM, TitleXS } from '@/atoms/Text'
import { useThemeContext } from '@/contexts/ThemeContext'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'
import { GuildEmailInput } from '../GuildEmailInput'

const DEFAULT_VOTING_IMAGE = '/v1711571585/angel-studios/guild/device-voting.webp'

interface GuildHowDoesItWorkProps {
  simplified?: boolean
  hasUser: boolean
}

export const GuildHowDoesItWork: React.FC<GuildHowDoesItWorkProps> = ({ simplified, hasUser }) => {
  const { t } = useTranslate('guild')
  const { votingPhoneImageUrl } = useGuildSignupContext()
  const { isDarkMode } = useThemeContext()

  const mainTextColor = isDarkMode ? 'white' : 'black'
  const explanationTextColor = isDarkMode ? 'core-gray-400' : 'core-gray-800'

  return (
    <section
      aria-labelledby="how-it-works-title"
      className={classNames('py-[104px]', isDarkMode ? 'bg-gradient-to-b from-black to-core-gray-950' : 'bg-white')}
    >
      <div className="grid grid-cols-12 ">
        <EyebrowMD
          id="how-it-works-title"
          as={AsH2}
          weight="bold"
          color={isDarkMode ? 'core-gray-500' : 'core-gray-800'}
          className="col-span-12 mb-16 text-center"
        >
          <Translate i18nKey="howDoesItWork" t={t}>
            How does it work?
          </Translate>
        </EyebrowMD>
        <HeadingXS
          as={AsSpan}
          weight="bold"
          color={mainTextColor}
          className="lg:photon-display col-span-12 col-start-1 col-end-13 mx-auto mb-8 max-w-[866px] self-center text-center lg:col-span-8 lg:col-start-3 lg:mb-16 se:max-w-[350px] se:text-2xl"
        >
          <Translate i18nKey="moreThanStreamingDecide" t={t}>
            More than just streaming. Decide what gets made.
          </Translate>
        </HeadingXS>
        <TitleXS
          as={AsSpan}
          weight="semibold"
          color={mainTextColor}
          className="lg:photon-heading-sm col-span-10 col-start-2 col-end-12 mx-auto mb-16 max-w-[866px] self-center text-center lg:col-span-8 lg:col-start-3 lg:mb-16"
        >
          <Translate i18nKey="angelInsiderHelpCreate" t={t}>
            Be an Angel insider, helping to curate a library of shows that amplify light.
          </Translate>
        </TitleXS>
        <Image
          height={600}
          width={293}
          src={votingPhoneImageUrl || DEFAULT_VOTING_IMAGE}
          alt="Guild Voting"
          className="pointer-events-none col-span-10 col-start-2 col-end-12 m-auto hidden touch-none select-none self-center lg:col-span-3 lg:col-start-3 lg:block lg:self-end 2xl:col-span-2 2xl:col-start-4"
        />
        <div className="col-span-10 col-start-2 col-end-12 lg:col-span-4 lg:col-start-6 lg:mt-8 lg:self-center lg:pl-16 2xl:col-start-7 2xl:col-end-12">
          <div className="mb-8 flex lg:mb-16">
            <div className="mr-4 flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-core-oxide-bright">
              <CheckIcon color="white" />
            </div>
            <div className="max-w-[462px]">
              <TitleSM as={AsH3} weight="bold" color={mainTextColor} className="photon-title-md">
                <Translate i18nKey="previewNewShowsMovies" t={t}>
                  Preview new ideas for shows and movies.
                </Translate>
              </TitleSM>
              {!simplified && (
                <ParagraphSM weight="medium" color={explanationTextColor} className="photon-paragraph-md">
                  <Translate i18nKey="exclusiveAccessFilmProposalsv1" t={t}>
                    Get exclusive access to film proposals, and help directors create things you <i>actually</i> want to
                    watch.
                  </Translate>
                </ParagraphSM>
              )}
            </div>
          </div>

          <div className="mb-8 flex lg:mb-16">
            <div className="mr-4 flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-core-oxide-bright">
              <CheckIcon color="white" />
            </div>
            <div className="max-w-[462px]">
              <TitleSM as={AsH3} weight="bold" color={mainTextColor} className="photon-title-md">
                <Translate i18nKey="giveFeedbackFilmShow" t={t}>
                  Give feedback on film or show proposals you love.
                </Translate>
              </TitleSM>
              {!simplified && (
                <ParagraphSM weight="medium" color={explanationTextColor} className="photon-paragraph-md">
                  <Translate i18nKey="answerQuestionsToVoteFilmmakers" t={t}>
                    Answer a few questions to cast your vote. Filmmakers review your feedback to make your favorites
                    even better.
                  </Translate>
                </ParagraphSM>
              )}
            </div>
          </div>
          <div className="flex">
            <div className="mr-4 flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-core-oxide-bright">
              <CheckIcon color="white" />
            </div>
            <div className="max-w-[462px]">
              <TitleSM as={AsH3} weight="bold" color={mainTextColor} className="photon-title-md">
                <Translate i18nKey="updatesProjectsExcitedAbout" t={t}>
                  Get updated on projects you’re excited about.
                </Translate>
              </TitleSM>
              {!simplified && (
                <ParagraphSM weight="medium" color={explanationTextColor} className="photon-paragraph-md">
                  <Translate i18nKey="receiveRegularUpdates" t={t}>
                    Receive regular updates on new shows, movies, and more. You’re an insider in the entertainment
                    industry.
                  </Translate>
                </ParagraphSM>
              )}
            </div>
          </div>
        </div>
        <Image
          height={600}
          width={293}
          src={votingPhoneImageUrl || DEFAULT_VOTING_IMAGE}
          alt="Guild Voting"
          className="pointer-events-none col-span-10 col-start-2 mx-auto mt-16 block touch-none select-none self-center lg:hidden"
        />
      </div>
      <div className="mx-auto mt-8 w-full max-w-[500px] lg:mt-16">
        <ParagraphMD
          className={classNames(
            'px-6 text-center font-[350] leading-7',
            isDarkMode ? '!text-core-gray-400' : '!text-core-gray-800',
          )}
        >
          <Translate i18nKey="manyMorePerksCollectiblesEarlyAccessv2" t={t}>
            With many more perks including exclusive early access & livestreams, merch discounts, and more!
          </Translate>
        </ParagraphMD>

        <div className="mt-8 flex min-h-[72px] w-full max-w-[600px] items-center justify-center">
          <GuildEmailInput hasUser={hasUser} isHero={true} />
        </div>
      </div>
    </section>
  )
}
